import React, { useEffect, useState,useCallback } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import Loader from '../../Common/Loader/loader'
import { useForm } from 'react-hook-form';
import { NavLink } from "react-router-dom";
import {toastr} from 'react-redux-toastr'
import Header from '../../../../layout/header/header';
import Footer from '../../../../layout/footer/footer';


let isRequested = false //when not request forgot password request,, true,false

function ResetPassword(props) {

    let [values,setState] = useState({
        email:"",
        loading:false,
        isRequested:false
    })


    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },
      } = useForm();

    const showHideLoader = (loading) =>{
        setState({
            ...values,
            loading:loading
        })
    }

    let onSubmit = handleSubmit((data) => {
        showHideLoader(true);
        let requestPasswordData = localStorage.getItem('isOtpVerified')
        if(!requestPasswordData) return toastr.error('error',"Something went wrong!");

        requestPasswordData = JSON.parse(requestPasswordData)
        let otpData = {
            OTP:requestPasswordData.OTP,
            email:requestPasswordData.email,
            ...data
        }

        // data.isRequested = values.isRequested //addign value that check password request check
        resetPassword(otpData).then((response) => {
            let { data } = response
            showHideLoader(false);
            if(response.status == 200){
                console.log('data',data)
                toastr.success('Success',data.message);
                props.history.push('/auth/login')
                localStorage.removeItem("isOtpVerified")
                localStorage.removeItem("isPasswordRequested")
                // if(values.isRequested){
                // }else{
                //     setState({
                //         ...values,
                //         isRequested:true
                //     })
                // }
            }else{
                toastr.error('Error',data.message);
                return;
            }
        }).catch((error) => {
            console.log('error',error)
            showHideLoader(false);
            toastr.error('Error',"Something went wrong!");
            return;
        });
    });

    return (
        <>
            <Header />
            <div className="page-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div>
                                <h3 className="text-center pb-5 pt-5">Reset Password</h3>
                            </div>
                            <form onSubmit={onSubmit}>
                                <div className="form-group">
                                    <div className="input-element">
                                        <span className="icon-block flaticon-lock"></span>
                                        <input
                                            type="password"
                                            placeholder="New Password"
                                            id="password"
                                            className="input-block"
                                            name="password"
                                            {...register("password",{
                                                required: "password is required*",
                                                maxLength:{
                                                    value:100,
                                                    message:"Maximum 100 charecter allowed*"
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.password && <p className="error">{errors.password.message}</p>}
                                </div>
                                <div className="form-group">
                                    <div className="input-element">
                                        <span className="icon-block flaticon-lock"></span>
                                        <input
                                        type="password"
                                        placeholder="Confirm Password"
                                        id="password"
                                        className="input-block"
                                        name="confirmPassword"
                                        {...register("confirmPassword",{
                                            required: "Confirm password is required*",
                                            maxLength:{
                                                value:100,
                                                message:"Maximum 100 charecter allowed*"
                                            },
                                            validate: {
                                                matchesPreviousPassword: (value) => {
                                                const { password } = getValues();
                                                return password === value || "Passwords should match!";
                                                }
                                            }
                                        })}
                                        />
                                    </div>
                                    {errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>}
                                </div>
                                <button className="btn-form primary-bg">Reset</button>
                            </form>
                        </div>
                        <div className="col-md-7">
                            <figure className="mb-0">
                                <img src="/media/images/confirm-pw.png" alt="" className="img-fluid" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
