import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
    listLoading: false,
    actionsLoading: false,
    address: null,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const appSlice = createSlice({
    name: "app",
    initialState: initialDashboardState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getDashboards
        addressFetched: (state, action) => {
            const { address } = action.payload;
            state.listLoading = false;
            state.address = address;
            state.error = null;
        },
        openLoginPopup: (state, action) => {
            const { showLoginPopup,showSignupPopup } = action.payload;
            state.listLoading = false;
            state.showLoginPopup = showLoginPopup;
            state.showSignupPopup = showSignupPopup;
            state.error = null;
        },
        openSignupPopup: (state, action) => {
            const { showLoginPopup,showSignupPopup } = action.payload;
            state.listLoading = false;
            state.showSignupPopup = showSignupPopup;
            state.showLoginPopup = showLoginPopup;
            state.error = null;
        },
    },
});
