// export const BASE_URL = "http://localhost:5000" //production base url
// export const BASE_URL = "http://localhost:5000" //production base url
export const BASE_URL = "https://api.findmech.com"  //production base url
// export const BASE_URL = "http://100.25.232.144:5000" //production base url
// export const CHAT_CONNECTION_URL = "http://100.25.232.144:5000" //local chat connection url
// export const CHAT_CONNECTION_URL = "http://192.168.1.98:5000" //local chat connection url
export const IMAGE_BASE_URL = "http://100.25.232.144:5000"
export const API_VERSION = "/api/v1/"

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i


// //This regex only allow numbers
//This regex allows numbers and brackets and plus and hiphen, eg (+91)-12321315-654
export const PHONE_REGEX = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/
export const DIAL_CODE_REGEX = /^(\+?\d{1,3}|\d{1,5})$/

export const SOCIAL_SECURITY_CODE_REGEX = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/


//Admin Auth
export const USER_LOGIN_URL = API_VERSION + "user/login";
export const USER_REGISTER_URL = API_VERSION + "user/register";
export const LOGOUT_URL = API_VERSION + "user/logout";
export const REQUEST_PASSWORD_URL = API_VERSION + "user/forgotPassword";
export const VERIFY_OTP = API_VERSION + "user/verifyOtp";
export const RESET_PASSWORD_URL = API_VERSION + "user/resetPassword";
export const SEND_OTP_TO_USER = API_VERSION + "user/sendOtp";
export const VERIFY_USER_OTP = API_VERSION + "user/verifyUserOTP";


export const EDIT_USER_PROFILE = API_VERSION + "user/resetPassword";
export const GET_USER_PROFILE = API_VERSION + "user/resetPassword";
export const EDIT_USER = API_VERSION + "user/resetPassword";

export const GET_ALL_CATEGORY = API_VERSION + "user/category/getActiveCategories";
export const GET_ALL_MECHANIC = API_VERSION + "user/getUsersByCategory";
export const GET_ALL_SPARE_PARTS = API_VERSION + "user/getSupplierSpareparts";

export const GET_OUR_ADDRESS = API_VERSION + "user/getAdminAddress";
export const GET_ABOUT_US = API_VERSION + "user/getAboutUs";
export const GET_PRIVACY_POLICY = API_VERSION + "user/getPrivacyPolicy";
export const GET_TERMS_CONDITION = API_VERSION + "user/getTermsAndCondition";
export const GET_ALL_FAQ = API_VERSION + "user/getAllFaq";

export const CONNECT_US = API_VERSION + "user/connectUs";
