import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Loader from '../../Common/Loader/loader'
import { useForm } from 'react-hook-form';
import { login,signup,sendOtpToUser,verifyUserOtp } from "../_redux/authCrud";
import {toastr} from 'react-redux-toastr'
import Autocomplete from "react-google-autocomplete";
import { EMAIL_REGEX, PHONE_REGEX,DIAL_CODE_REGEX } from '../../apiUrl';
import { Modal } from "react-bootstrap";




function Register(props) {

    let [values,setState] = useState({
        email:"",
        password:"",
        loading:false,
        showModel:true,
        disableButton:false
    })

    let [signupFormData,setSignupFormData] = useState('');
    let [requestedForOtp,sendOtpRequest] = useState(false);

    let hideModel = function(){
        // setState({
        //     ...values,
        //     showModel:false
        // })
        // redirect()
        props.hideSignupPopup()
    }

    let goToLogin = function(){
        props.openLoginPopup()
    }

    let redirect = function(){
        props.history.push('/')
    }

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const showHideLoader = (loading) =>{
        setState({
            ...values,
            loading:loading
        })
    }

    let onPlaceSelected = (place) => {
        setValue('address',place.formatted_address)
        setValue('latitude',place.geometry.location.lat())
        setValue('longitude',place.geometry.location.lng())
    }

    let onSubmit = handleSubmit((data) => {
        console.log('data',data)
        showHideLoader(true);
        data.signUpFrom = 'web';
        console.log("signupFormData",signupFormData)
        if(data.OTP && (data.getOtpOn == signupFormData?.getOtpOn)){
            verifyOtp(data)
        }else{
            sendOtp(data)
        }

    });


    const registerUser = (data)=>{
        signup(data).then((response) => {
            console.log('response',response)
            showHideLoader(false);
            if(response.data) {
                let {data} = response
                if(response.status=='200'){
                    toastr.success('Success',data.message)
                    // props.history.push('/')
                    hideModel()
                    // props.history.push('/auth/login')
                }else{
                    toastr.error('Error',data.message)
                    return;
                }
            }
        }).catch((error) => {
            showHideLoader(false);
            console.log('error',error)
            toastr.error('Error',error.message)
        });
    }

    const verifyOtp = (userSignupFormData)=>{
        console.log("verifyOtp data",userSignupFormData)

        let optData = {
            OTP:userSignupFormData.OTP
        }
        if(userSignupFormData.getOtpOn == 'email'){
            optData.email = userSignupFormData.email
        }else{
            optData.mobileNumber = userSignupFormData.mobileNumber
            optData.dialCode = userSignupFormData.dialCode
        }

        verifyUserOtp(optData).then((response)=>{
            if(response.data) {
                let {data} = response
                if(response.status=='200'){
                    delete signupFormData.getOtpOn
                    delete signupFormData.OTP
                    registerUser(signupFormData);
                    // toastr.success('Success',data.message)
                }else{
                    toastr.error('Error',data.message)
                    return;
                }
            }
        });
    }

    const sendOtp = (userSignupFormData)=>{
        console.log("sendOtp data",userSignupFormData)
        let optData = {}
        if(userSignupFormData.getOtpOn == 'email'){
            optData.email = userSignupFormData.email
        }else{
            optData.mobileNumber = userSignupFormData.mobileNumber
            optData.dialCode = userSignupFormData.dialCode
        }
        sendOtpToUser(optData).then((response)=>{
            if(response.data) {
                let {data} = response
                if(response.status=='200'){
                    setSignupFormData(userSignupFormData)
                    sendOtpRequest(true);
                    toastr.success('Success',data.message)
                }else{
                    toastr.error('Error',data.message)
                    return;
                }
            }
        });
    }

    let selectGetOtpOn = ()=>{
        setValue("OTP",'')
        sendOtpRequest(false);
    }

    return (
        <>
            <Modal
                size="lg" //lg, sm, xl
                show={true}
                onHide={hideModel}
                // onHide={}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Body className="overlay cursor-default">
                    <div className="modal-body loginbody">
                        <div className="row">
                            <div className="col-md-6 loginbg">
                                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000" >
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src="/media/images/login_left.png" className="d-block w-100" alt="..." />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>NEED YOUR CAR REPAIRED?</h5>
                                                <p>Findmech brings to you a digitalised way of taking care of your automobile with ease.</p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/media/images/login_left.png" className="d-block w-100" alt="..." />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>Second slide label</h5>
                                                <p>Some representative placeholder content for the second slide.</p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/media/images/login_left.png" className="d-block w-100" alt="..." />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>Third slide label</h5>
                                                <p>Some representative placeholder content for the third slide.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="float-end btn-close-popup" onClick={hideModel} data-bs-dismiss="modal">X</button>
                                <img className="img-fluid logoclass" src="/media/images/logo2.png"/>
                                <p className="login_head">Welcome to FindMech</p>
                                <div className="card-body">
                                    <form id="login_form" onSubmit={onSubmit}>
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="text"
                                                placeholder="Name"
                                                className="form-control"
                                                name="name"
                                                {...register("name",{
                                                    required: "Name is required*",
                                                    maxLength:{
                                                        value:100,
                                                        message:"Maximum 100 charecter allowed*"
                                                    },
                                                })}
                                                defaultValue=""
                                                />
                                            </div>
                                            {errors.name && <p className="error">{errors.name.message}</p>}
                                        </div>
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="email"
                                                placeholder="Email"
                                                className="form-control"
                                                name="email"
                                                {...register("email",{
                                                    required: "Email is required*",
                                                    maxLength:{
                                                        value:100,
                                                        message:"Maximum 100 charecter allowed*"
                                                    },
                                                    pattern: {
                                                        value: EMAIL_REGEX,
                                                        message: "Enter a valid email*",
                                                    },
                                                })}
                                                defaultValue=""
                                                />
                                            </div>
                                            {errors.email && <p className="error">{errors.email.message}</p>}
                                        </div>
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="text"
                                                placeholder="Dial Code"
                                                className="form-control"
                                                name="dialCode"
                                                {...register("dialCode",{
                                                    required: "Dial code is required*",
                                                    maxLength:{
                                                        value:5,
                                                        message:"Maximum 5 digits allowed*"
                                                    },
                                                    pattern: {
                                                        value:DIAL_CODE_REGEX,
                                                        message: "Enter a valid dial code*",
                                                    },
                                                })}
                                                defaultValue=""
                                                />
                                            </div>
                                            {errors.dialCode && <p className="error">{errors.dialCode.message}</p>}
                                        </div>
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="text"
                                                placeholder="Phone Number"
                                                className="form-control"
                                                name="mobileNumber"
                                                {...register("mobileNumber",{
                                                    required: "Phone is required*",
                                                    maxLength:{
                                                        value:10,
                                                        message:"Maximum 10 digits allowed*"
                                                    },
                                                    pattern: {
                                                        value:PHONE_REGEX,
                                                        message: "Enter a valid phone no.*",
                                                    },
                                                })}
                                                defaultValue=""
                                                />
                                            </div>
                                            {errors.mobileNumber && <p className="error">{errors.mobileNumber.message}</p>}
                                        </div>
                                        {/* <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="date"
                                                name="DOB"
                                                className="form-control"
                                                {...register("DOB",{
                                                    // required: "Date of birth is required*",
                                                })}
                                                defaultValue=""
                                                />
                                            </div>
                                            {errors.DOB && <p className="error">{errors.DOB.message}</p>}
                                        </div> */}
                                        
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <Autocomplete
                                                type="text"
                                                className="form-control"
                                                apiKey={process.env.REACT_APP_GOOGLE_MAP_API || 'AIzaSyBQRDtVRJFf9NKa0ARx0WNXeJszr5-Ktb0'}
                                                language="en"
                                                {...register("address", {
                                                    required: "Address is required*",
                                                    maxLength: {
                                                        value: 1000,
                                                        message: "Maximum 1000 charecter allowed*"
                                                    },
                                                })}
                                                onPlaceSelected={onPlaceSelected}
                                                />
                                            </div>
                                            {errors.address && <p className="error">{errors.address.message}</p>}
                                        </div>
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="password"
                                                placeholder="Password"
                                                className="form-control"
                                                name="password"
                                                {...register("password",{
                                                    required: "Password is required*",
                                                })}
                                                defaultValue=""
                                                />
                                            </div>
                                            {errors.password && <p className="error">{errors.password.message}</p>}
                                        </div>
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="password"
                                                placeholder="Confirm Password"
                                                className="form-control"
                                                name="confirmPassword"
                                                {...register("confirmPassword",{
                                                    required: "Confirm password is required*",
                                                })}
                                                />
                                            </div>
                                            {errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>}
                                        </div>

                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <label htmlFor="filled-in-box">Get OTP On</label>
                                            </div>
                                            <div className="input-group form-group">
                                                <input
                                                type="radio"
                                                id="filled-in-phone"
                                                name="getOtpOn"
                                                value="phone"
                                                onClick={()=>selectGetOtpOn()}
                                                {...register("getOtpOn",{
                                                    required: "Please specify any one*",
                                                })}
                                                defaultChecked={true}
                                                />
                                                <label htmlFor="filled-in-phone" className="select-phone">Phone</label>

                                                <input
                                                type="radio"
                                                id="filled-in-email"
                                                name="getOtpOn"
                                                value="email"
                                                onClick={()=>selectGetOtpOn()}
                                                {...register("getOtpOn",{
                                                    required: "Please specify any one*",
                                                })}
                                                />
                                                <label htmlFor="filled-in-email" className="select-email">Email</label>

                                            </div>
                                            {errors.getOtpOn && <p className="error">{errors.getOtpOn.message}</p>}
                                        </div>
                                        {
                                            requestedForOtp && (
                                                <div className="input-element">
                                                    <div className="input-group form-group">
                                                        <input
                                                        type="text"
                                                        placeholder="OTP"
                                                        className="form-control"
                                                        name="OTP"
                                                        {...register("OTP",{
                                                            required: "OTP is required*",
                                                        })}
                                                        />
                                                    </div>
                                                    <div className="input-group-resend">
                                                        <label onClick={()=>onSubmit()}>Resend</label>
                                                    </div>
                                                    {errors.OTP && <p className="error">{errors.OTP.message}</p>}
                                                </div>
                                            )
                                        }

                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="checkbox"
                                                id="filled-in-box"
                                                className="mr-1"
                                                {...register("termsAndCondition",{
                                                    required: "Terms & condition is required*",
                                                })}
                                                />
                                                <label htmlFor="filled-in-box"></label>
                                                &nbsp; I agree to the &nbsp;
                                                <Link to="#" className="mr-1"> terms of service</Link>
                                            </div>
                                            {errors.termsAndCondition && <p className="error">{errors.termsAndCondition.message}</p>}
                                        </div>
                                        <div className="form-group">
                                            <input type="submit" disabled={values.disableButton} value="Sign Up" className="btn float-right login_btn" />
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center links">
                                        Already have an account?
                                        <Link to="#" onClick={goToLogin} className="signup_text"> Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
  );
}

export default injectIntl(connect(null, auth.actions)(Register));
