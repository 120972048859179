import {
    getAppAddress
} from './appCrud';
import { toastr } from "react-redux-toastr";

import { appSlice, callTypes } from './appReducer'
const { actions } = appSlice;


export const fetchAppAddress = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return getAppAddress().then((response) => {
        dispatch(actions.endCall({ callType: callTypes.list }));
        if(response.status == 200){
            if (response.data && response.data.status=='success') {
                const {data } = response.data;
                dispatch(actions.addressFetched({ address:data }));
            } else {
                toastr.error("Error", response.data.message);
                return response.data;
            }
        }else{
            toastr.error("Error", response.data.message);
            return response.data;
        }
    }).catch((error) => {
        console.log('error',error)
        error.clientMessage = "Something went wrong!";
        toastr.error("Error", error.clientMessage);
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        return {status:"failure",message:"Something went wrong!"};
    });
};

export const openHideSignupPopup = (data) => (dispatch) => {
    const { showLoginPopup,showSignupPopup } = data;
    dispatch(actions.openSignupPopup({ showLoginPopup:showLoginPopup,showSignupPopup:showSignupPopup }));
};

export const openHideLoginPopup = (data) => (dispatch) => {
    const { showLoginPopup,showSignupPopup } = data;
    dispatch(actions.openLoginPopup({ showLoginPopup:showLoginPopup,showSignupPopup:showSignupPopup }));
};

