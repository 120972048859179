import React, { useEffect, useState,useCallback } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import Loader from '../../Common/Loader/loader'
import { useForm } from 'react-hook-form';
import { NavLink } from "react-router-dom";
import {toastr} from 'react-redux-toastr'

function RegisterSuccessfully(props) {
    return (
        <>
             <div className="page-block ">
                <div className=" ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto text-center">
                                <img src="/media/images/success.jpg" alt="" className="img-fluid" />
                                <h3 className="my-4">Register successful</h3>
                                <a href="login.html" className="btn-form primary-bg text-white">Back to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(RegisterSuccessfully));
