import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Loader from '../../Common/Loader/loader'
import { useForm } from 'react-hook-form';
import { login } from "../_redux/authCrud";
import {toastr} from 'react-redux-toastr'
import { EMAIL_REGEX } from '../../apiUrl';
import { Modal } from "react-bootstrap";


import ForgotPassword from "./ResetPassword";


function Login(props) {
    let [values,setState] = useState({
        email:"",
        password:"",
        loading:false,
        showModel:true,
        disableButton:false
    })

    let hideModel = function(){
        // setState({
        //     ...values,
        //     showModel:false
        // })
        // redirect()
        props.hideLoginPopup();
    }

    let goToSignup = function(){
        props.openSignupPopup()
    }

    let redirect = function(){
        props.history.push('/')
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const showHideLoader = (loading) =>{
        setState({
            ...values,
            loading:loading
        })
    }

    let onSubmit = handleSubmit((data) => {
        showHideLoader(true);
        let firebase_token = "dummyToken"
        login(data.email, data.password,firebase_token).then((response) => {
            showHideLoader(false);
            if(response.data) {
                let {data} = response
                if(response.status=='200'){
                    if (!data.token) {
                        toastr.error('Error',data.message)
                        return;
                    }else{
                        props.login(data.token, data.data);
                        // props.history.push('/')
                        hideModel()
                        toastr.success('Success',data.message)
                    }
                }else{
                    toastr.error('Error',data.message)
                    return;
                }
            }
        }).catch((error) => {
            showHideLoader(false);
            console.log('error',error)
            toastr.error('Error',error.message)
        });
    });

    const passwordShow = ()=>{
        console.log('show password')
    }

    return (
        <>
            <Modal
                size="lg" //lg, sm, xl
                show={true}
                onHide={hideModel}
                // onHide={}
                aria-labelledby="example-modal-sizes-title-lg"
                className="modal-login-container"
            >
                <Modal.Body className="overlay cursor-default modal-login-body">
                    <div className="modal-body loginbody">
                        <div className="row">
                            <div className="col-md-6 loginbg">
                                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000" >
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src="/media/images/login_left.png" className="d-block w-100" alt="..." />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>NEED YOUR CAR REPAIRED?</h5>
                                                <p>Findmech brings to you a digitalised way of taking care of your automobile with ease.</p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/media/images/login_left.png" className="d-block w-100" alt="..." />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>Second slide label</h5>
                                                <p>Some representative placeholder content for the second slide.</p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/media/images/login_left.png" className="d-block w-100" alt="..." />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>Third slide label</h5>
                                                <p>Some representative placeholder content for the third slide.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="float-end btn-close-popup" onClick={hideModel} data-bs-dismiss="modal">X</button>
                                <img className="img-fluid logoclass" src="/media/images/logo2.png"/>
                                <p className="login_head">Welcome to FindMech</p>
                                <div className="card-body">
                                    <form id="login_form" onSubmit={onSubmit}>
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                {...register("email",{
                                                    required: "Email is required*",
                                                    maxLength:{
                                                        value:100,
                                                        message:"Maximum 100 charecter allowed*"
                                                    },
                                                    pattern: {
                                                        value: EMAIL_REGEX,
                                                        message: "Enter a valid email*",
                                                    },
                                                })}
                                                />
                                            </div>
                                            {errors.email && <p className="error">{errors.email.message}</p>}
                                        </div>
                                        <div className="input-element">
                                            <div className="input-group form-group">
                                                <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                {...register("password",{
                                                    required: "Password is required*",
                                                    maxLength:{
                                                        value:100,
                                                        message:"Maximum 100 charecter allowed*"
                                                    },
                                                })}
                                                />
                                            </div>
                                            {errors.password && <p className="error">{errors.password.message}</p>}
                                        </div>
                                        <div className="form-group forgot_div">
                                            <Link to="/auth/forgot-password" onClick={hideModel} className="forgot float-end">Forgot your password?</Link>
                                        </div>
                                        <div className="form-group">
                                            <input type="submit" disabled={values.disableButton} value="Login" className="btn float-right login_btn" />
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center links">
                                        Don't have an account?
                                        <Link to="#" onClick={goToSignup} className="signup_text"> Sign Up</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default connect(null, auth.actions)(Login);
