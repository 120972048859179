//@ts-check
import axios from "axios";
import {
    USER_REGISTER_URL,
    USER_LOGIN_URL,
    LOGOUT_URL,
    VERIFY_OTP,
    REQUEST_PASSWORD_URL,
    EDIT_USER_PROFILE,
    GET_USER_PROFILE,
    RESET_PASSWORD_URL,
    SEND_OTP_TO_USER,
    VERIFY_USER_OTP
} from '../../apiUrl.js'

export const ME_URL = "api/me";

export function login(email, password,firebase_token) {
    return axios.post(USER_LOGIN_URL, { email, password });
}

export function signup(data) {
    return axios.post(USER_REGISTER_URL,data);
}

export function sendOtpToUser(data) {
    return axios.post(SEND_OTP_TO_USER,data);
}

export function verifyUserOtp(data) {
    return axios.post(VERIFY_USER_OTP,data);
}

export function updateUserProfile(data,user_type) {
    let url = EDIT_USER_PROFILE
    return axios.post(url,data);
}

export function getUserProfile(user_type) {
    return axios.get(GET_USER_PROFILE);
}

export function requestPassword(data) {
    let url = REQUEST_PASSWORD_URL
    // if(data.isRequested){
    //     url = RESET_PASSWORD_URL
    // }
    // delete data['isRequested']
    return axios.post(url,data);
}

export function resetPassword(data) {
    let url = RESET_PASSWORD_URL
    return axios.post(url,data);
}

export function verifyOtp(data) {
    return axios.post(VERIFY_OTP,data);
}

export function logout() {
    // return axios.post(LOGOUT_URL, {});
    return new Promise((resolve, reject) => {resolve({})});
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}
