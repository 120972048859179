import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../layout/_core/SplashScreen";
import * as auth from "../_redux/authRedux";
import { logout } from "../_redux/authCrud";

class Logout extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount() {
        // console.log('this.props',this.props)
        logout().then((res) => {
            this.props.logout();
            this.props.history.push('/')
        }).catch((err) => {
            this.props.logout();
            console.log(err);
        });
    }

    render() {
        const { hasAuthToken } = this.props;
        return ('')
    }
}

export default connect(
    ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
    auth.actions
)(Logout);
