import React, { useEffect,lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../layout/main/layout";
import BasePage from "./BasePage";
// import styled from 'styled-components';
import { shallowEqual, useDispatch,connect, useSelector } from "react-redux";
import {
    Login,
    VerifyOtp,
    Signup,
    Logout,
    ForgotPassword,
    ResetPassword,
    RegisteredSuccessfully
} from "./containers/Auth";

// import Login from  "./containers/Auth/pages/Login"

import ErrorPage from "../app/components/errorPage";
import NotFoundPage from "../app/components/404Page";
import UnauthorizedPage from "../app/components/unauthorizedPage";

const DashboardPages = lazy(() => import("./containers/dashboard/index"));
const Category = lazy(() => import("./containers/dashboard/pages/category"));
const MechanicLists = lazy(() => import("./containers/dashboard/pages/mechanicLists"));
const SparePartLists = lazy(() => import("./containers/dashboard/pages/sparePartLists"));
const PrivacyPolicy = lazy(() => import("./containers/cms/privacyPolicy"));
const TermsAndCondition = lazy(() => import("./containers/cms/termsAndCondition"));
const Aboutus = lazy(() => import("./containers/cms/aboutUs"));
const OurTeam = lazy(() => import("./containers/cms/ourTeam"));
const Explore = lazy(() => import("./containers/cms/explore"));
const ContactUs = lazy(() => import("./containers/cms/contactUs"));
const Apps = lazy(() => import("./containers/cms/apps"));
const DownloadOurApp = lazy(() => import("./containers/cms/qr-buttonComponent"));


function Routes(props){

    const { isAuthorized,user_type } = useSelector(({ auth }) => ({
            isAuthorized: auth.user != null,
            // user_type:auth.user?.user_type
        }),
        shallowEqual
    );

    let routes = (
        <Switch>
            <Route exact path="/error" component={ErrorPage} />
            <Route exact path="/404" component={NotFoundPage} />
            <Route exact path="/"  component={DashboardPages} />
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/auth/verify-otp" component={VerifyOtp} />
            <Route exact path="/auth/signup" component={Signup} />
            <Route exact path="/auth/forgot-password" component={ForgotPassword} />
            <Route exact path="/auth/reset-password" component={ResetPassword} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/terms" component={TermsAndCondition} />
            <Route exact path="/about-us" component={Aboutus} />
            <Route exact path="/apps" component={Apps} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/our-teams" component={OurTeam} />
            <Route exact path="/explore" component={Explore} />
            <Route exact path="/click-to-download-our-app" component={DownloadOurApp} />
            <Redirect to="/" />
        </Switch>
    );

    if (isAuthorized) {
        routes = (
            <Switch>
                <Route exact path="/"  component={DashboardPages} />
                <Route exact path="/category"  component={Category} />
                <Route exact path="/mechanic-lists/:categoryId"  component={MechanicLists} />
                <Route exact path="/supplier-spare-parts/:supplierId"  component={SparePartLists} />
                <Route exact path="/auth/registered-successfully" component={RegisteredSuccessfully} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/error" component={ErrorPage} />
                <Route exact path="/404" component={NotFoundPage} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                <Route exact path="/terms" component={TermsAndCondition} />
                <Route exact path="/about-us" component={Aboutus} />
                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/our-teams" component={OurTeam} />
                <Route exact path="/explore" component={Explore} />
                <Route exact path="/click-to-download-our-app" component={DownloadOurApp} />
                <Redirect to="/" />
            </Switch>
        );
    }

    return (
        <div>
            {routes}
        </div>
    );
}

export default Routes
