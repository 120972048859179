import * as utils from "./LocalStorageHelpers";

const localStorageLastLocationKey = "metronic-lastLocation";

function acceptLocation(lastLocation) {
    if (
        lastLocation &&
        lastLocation.pathname &&
        lastLocation.pathname !== "/" &&
        lastLocation.pathname.indexOf("auth") === -1 &&
        lastLocation.pathname !== "/logout"
    ) {
        return true;
    }

    return false;
}

export function saveLastLocation(lastLocation) {
    if (acceptLocation(lastLocation)) {
        utils.setStorage(
            localStorageLastLocationKey,
            JSON.stringify(lastLocation),
            120
        );
    }
}

export function forgotLastLocation() {
    utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
    const defaultLocation = { pathname: "/", title: "Dashboard" };
    const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
    if (!localStorateLocations) {
        return { pathname: "/", title: "Dashboard" };
    }

    try {
        const result = JSON.parse(localStorateLocations);
        return result;
    } catch (error) {
        console.error(error);
        return defaultLocation;
    }
}

export function getCurrentUrl(location) {
    return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url, exact = false) {
    const current = getCurrentUrl(location);
    if (!current || !url) {
        return false;
    }

    console.log("current",current)
    console.log("url",url)

    if (current === url) {
        return true;
    }
    const indexLimit = exact ? 0 : -1;
    if (current.indexOf(url) > indexLimit) {
        return true;
    }

    return false;
}

export function checkIsActiveMenu(location, url) {
    // const current = getCurrentUrl(location);
    // if (!current || !url) {
    //     return false;
    // }

    // console.log("current",location?.pathname)
    // console.log("url",url)

    if (location?.pathname === url) {
        return true;
    }

    // const indexLimit = exact ? 0 : -1;
    // if (location?.pathname.indexOf(url) > indexLimit) {
    //     return true;
    // }

    return false;
}
