//@ts-check
import React, { Suspense, lazy,useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../layout/_core/SplashScreen";
import {useSelector,shallowEqual,useDispatch,connect,} from 'react-redux';
import { useLocation } from "react-router";
import {getUserProfile} from "./containers/Auth/_redux/authCrud";
import {actions} from "./containers/Auth/_redux/authRedux";


const DashboardPages = lazy(() => import("./containers/dashboard/index"));

const NotFound = lazy(() => import("./components/404Page"));

function BasePage(props) {
    const location = useLocation();

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* <Redirect exact from="/" to="/" /> */}
                <Route path="/"  component={DashboardPages} />
                <Redirect to="/404" />
            </Switch>
        </Suspense>
    );
}


const mapDispatchToProps = (dispatch)=>{
    return{
        saveData : (data)=> dispatch(actions.setUser(data))
    }
}
export default connect(null,mapDispatchToProps)(BasePage)
