import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    // Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    // resetPassword: "[Reset password] Action",
    // UserLoaded: "[Load User] Auth API",
    SetUser: "[Set User] Action",
  };

    const initialAuthState = {
        user: undefined,
        authToken: undefined,
        // resetPassword: undefined,
    };

    export const reducer = persistReducer(
        { storage, key: "v713-tmsez-auth", whitelist: ["user", "authToken"] },
        (state = initialAuthState, action) => {
            // console.log('action.type',action.type)
            switch (action.type) {
                case actionTypes.Login: {
                    // console.log('action.payload',action.payload)
                    const { authToken, user } = action.payload;
                    delete user['otp'];
                    delete user['otp_expiry'];
                    return { authToken, user: user };
                }

                case actionTypes.Logout: {
                    // TODO: Change this code. Actions in reducer aren't allowed.
                    return initialAuthState;
                }

                case actionTypes.SetUser: {
                    let { user } = action.payload;
                    delete user['otp'];
                    delete user['otp_expiry'];
                    return { ...state, user };
                }

                // case actionTypes.resetPassword: {
                //     let { data } = action.payload;
                //     console.log('dataaa',data)

                //     return { resetPassword:data, ...state };
                // }

                default:
                    return state;
            }
        }
    );

    export const actions = {
        login: (authToken, user) => ({
            type: actionTypes.Login,
            payload: { authToken, user },
        }),

        forgorPassword: (data) => ({
            type: actionTypes.forgot_password,
            payload: { data },
        }),

        //When user has requested for forgot password
        requestUser: (user) => ({
            type: actionTypes.UserRequested,
            payload: { user },
        }),

        logout: () => ({ type: actionTypes.Logout }),

        setUser: (user) => ({
            type: actionTypes.SetUser,
            payload: { user }
        }),

        // resetPassword: (data) => ({
        //     type: actionTypes.resetPassword,
        //     payload: { data }
        // }),
    };

    // export function* saga() {
    //     // yield takeLatest(actionTypes.Login, function* loginSaga() {
    //     //   // yield put(actions.requestUser());
    //     // });

    //     yield takeLatest(actionTypes.Register, function* registerSaga() {
    //         yield put(actions.requestUser());
    //     });

    //     yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    //         const { data: user } = yield getUserByToken();

    //         yield put(actions.fulfillUser(user));
    //     });
    // }
