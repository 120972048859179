//@ts-check
// import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import * as auth from "../app/containers/Auth/_redux/authRedux";
import { dashboardSlice  } from "../app/containers/dashboard/_redux/dashboardReducer";
import { appSlice  } from "../layout/appRedux/appReducer";


export const rootReducer = combineReducers({
    toastr: toastrReducer,
    auth: auth.reducer,
    dashboard:dashboardSlice.reducer,
    app:appSlice.reducer,
//  users: userSlice.reducer,
});
