import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Loader from '../../Common/Loader/loader'
import { useForm } from 'react-hook-form';
import { login, verifyOtp } from "../_redux/authCrud";
import {toastr} from 'react-redux-toastr'
import { EMAIL_REGEX } from '../../apiUrl'
import Header from '../../../../layout/header/header';
import Footer from '../../../../layout/footer/footer';

// import ForgotPassword from "./ResetPassword";


function VerifyOTP(props) {
    let [values,setState] = useState({
        email:"",
        password:"",
        loading:false
    })

    const {
        register,
        setFocus,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const showHideLoader = (loading) =>{
        setState({
            ...values,
            loading:loading
        })
    }

    let enterOtpInput = (event,key)=>{
        const { value } = event.target
        if(value){
            setFocus(`otp${key+1}`)
        }
    }

    let onSubmit = handleSubmit((formdata) => {
        // console.log('formdata',formdata)
        showHideLoader(true);

        let requestPasswordData = localStorage.getItem('isPasswordRequested')
        if(!requestPasswordData) return toastr.error('error',"Something went wrong!");

        requestPasswordData = JSON.parse(requestPasswordData)
        let otpData = {
            OTP:`${formdata.otp1}${formdata.otp2}${formdata.otp3}${formdata.otp4}`,
            email:requestPasswordData.email
        }

        verifyOtp(otpData).then((response) => {
            let { data } = response
            // console.log('response',response)
            showHideLoader(false);
            if(response.status == 200){
                // console.log('data',data)
                toastr.success('Success',data.message);
                localStorage.setItem('isOtpVerified',JSON.stringify({isOtpVerified:true,email:otpData.email,OTP:otpData.OTP}))
                props.history.push(`/auth/reset-password?email=${otpData.email}`)
            }else{
                toastr.error('Error',data.message);
                return;
            }
        }).catch((error) => {
            console.log('error',error)
            showHideLoader(false);
            toastr.error('Error',"Something went wrong!");
            return;
        });
    });

    return (
        <>
            <Header />
            <div className="page-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <div className="row">
                                <div className="col-sm-12 mt-5">
                                    <h3 className="title">
                                        Verify OTP
                                    </h3>
                                    <form onSubmit={onSubmit} className="mt-5 otp">
                                        <input
                                        className="otp mr-1"
                                        type="text"
                                        name='otp1'
                                        onInput={(event)=>enterOtpInput(event,1)}
                                        placeholder="0"
                                        maxLength={1}
                                        {...register("otp1",{
                                            required: "OTP is required*",
                                            maxLength:{
                                                value:1,
                                                message:"Maximum 1 charecter allowed*"
                                            },
                                        })}

                                        />

                                        <input
                                        className="otp mr-1"
                                        type="text"
                                        placeholder="0"
                                        name='otp2'
                                        onInput={(event)=>enterOtpInput(event,2)}
                                        maxLength={1}
                                        {...register("otp2",{
                                            required: "OTP is required*",
                                            maxLength:{
                                                value:1,
                                                message:"Maximum 1 charecter allowed*"
                                            },
                                        })}
                                        />

                                        <input
                                        className="otp mr-1"
                                        type="text"
                                        placeholder="0"
                                        name='otp3'
                                        onInput={(event)=>enterOtpInput(event,3)}
                                        maxLength={1}
                                        {...register("otp3",{
                                            required: "OTP is required*",
                                            maxLength:{
                                                value:1,
                                                message:"Maximum 1 charecter allowed*"
                                            },
                                        })}
                                        />

                                        <input
                                        className="otp mr-1"
                                        type="text"
                                        name='otp4'
                                        // onInput={()=>enterOtpInput(4)}
                                        placeholder="0"
                                        maxLength={1}
                                        {...register("otp4",{
                                            required: "OTP is required*",
                                            maxLength:{
                                                value:1,
                                                message:"Maximum 1 charecter allowed*"
                                            },
                                        })}
                                        />
                                        {/* <div className="form-group forgot_div">
                                            <button className='btn btn-main-with-hover px-5 mt-4 mb-4 text-white'>Resend OTP</button>
                                        </div> */}
                                        {/* <hr className="mt-4" /> */}
                                        <p>
                                            <button type="submit" className='btn btn-main-submit px-5 mt-4 mb-4 text-white'>Verify</button>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <figure>
                                <img src="/media/images/otp.png" alt="" className="img-fluid" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* <Loader isShow={values.loading}/> */}
        </>
    );
}

// export default connect(null, auth.actions)(VerifyOTP);
export default VerifyOTP;
