import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    DashboardDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialDashboardState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getDashboards
        categoryFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.category = entities;
            // state.totalCount = totalCount;
            state.error = null;
        },
        // getDashboards
        mechanicFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.mechanic = entities;
            // state.totalCount = totalCount;
            state.error = null;
        },
        aboutusFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.aboutus = entities;
            // state.totalCount = totalCount;
            state.error = null;
        },
        sparePartFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.sparePart = entities;
            // state.totalCount = totalCount;
            state.error = null;
        },
    },
});
