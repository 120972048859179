//@ts-check
import React,{useState} from 'react';
import QRCode from "react-qr-code";


let QrComponent = (props) => {
    return (
        <>
            <div
            className='qrgenerator'
            >
                <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={props.link}
                viewBox={`0 0 256 256`}
                />
            </div>
        </>
    )
}

export default QrComponent
